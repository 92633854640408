import React from "react";
import { ToggleButton } from "components/ToggleButton";

function CurrencyTag({ title, id, onSelect, selectedValue }) {
  return (
    <ToggleButton
      name="currency"
      id={id}
      pressed={selectedValue}
      onClick={onSelect}
    >
      {title}
    </ToggleButton>
  );
}

export default CurrencyTag;
