import React from "react";
import {
  PaymentModalMessage,
  PendingPaymentModal,
} from "components/partner/billing/dlocal_smart_fields";
import { getCurrencyCountry } from "util/format_helpers";

function PaymentNotificationModal({
  paymentStatus,
  onClick,
  show,
  paymentRef,
  currency,
  amount,
}) {
  if (!show) {
    return null;
  }
  let title = "Payment Rejected";
  let message = "";
  const isPending = paymentStatus === "pending";
  const isApproved = paymentStatus === "approved";
  const isExpired = paymentStatus === "expired";

  if (isApproved) {
    title = "Funds Added Successfully!";
    message =
      "It may take a few moments for your balance to reflect your most recent payment.";
  } else if (isExpired) {
    title = "Session Expired";
    message = "Please retry payment";
  }

  if (isPending) {
    return (
      <PendingPaymentModal
        currency={currency}
        amount={amount}
        params={{
          message: "Please wait while we confirm payment.",
          payment_ref: paymentRef,
          country: getCurrencyCountry(currency),
        }}
        onClick={onClick}
      />
    );
  }

  return (
    <PaymentModalMessage
      onClick={onClick}
      title={title}
      message={message}
      error={!isApproved}
    />
  );
}

export default PaymentNotificationModal;
